<template>
  <div class="paySuccess">
    <div class="close passIconfont  close icon-ftshanchu"
         @click="closeWeb"></div>
    <div class="pay_title">支付</div>
    <div class="passIconfont icon-fttongguowancheng success_icon"></div>
    <p class="success_tips">您已支付成功</p>
    <p class="tips">请返回游戏内查看</p>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
  data () {
    return {}
  },
  methods: {
    // 关闭页面与游戏通信
    closeWeb () {
      alert('关闭页面与游戏通信')
    },
  }
}
</script>


<style lang="scss">
.paySuccess {
  .close {
    width: 25px;
    height: 25px;
    font-size: 25px;
    position: absolute;
    top: 30px;
    right: 39px;
    cursor: pointer;
  }

  .pay_title {
    width: 100%;
    font-size: 30px;
    text-align: center;
    font-family: "SimHei";
    margin-bottom: 20px;
  }
  .success_icon {
    width: 120px;
    height: 120px;
    font-size: 120px;
    color: #ff7391;
    margin: 0 auto;
    margin-top: 120px;
  }
  .success_tips {
    font-size: 30px;
    color: #575757;
    text-align: center;
    font-weight: 700;
    margin-top: 60px;
    font-family: "SimHei";
  }
  .tips {
    font-size: 24px;
    color: #575757;
    text-align: center;
    margin-top: 15px;
    font-family: "SimHei";
  }
}
</style>

